/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Flex, Image, Text, Select } from "@chakra-ui/react";

//images
import Logo from "../../images/logo-icon.svg";

//components
import NavLink from "../Navbar/NavLink/NavLink";

export const NAVBAR_HEIGHT = 80;

const Navbar = ({
  setTab,
  tabs = ["top", "new", "best", "show"],
  setCount,
}) => {
  const [activeTab, setActiveTab] = useState("top");

  const handleChange = (e) => {
    setCount(0);
    setTab(e.target.value);
    console.log(e.target.value);
  };

  return (
    <>
      <Flex alignItems="center" ml={8} bgColor="#ffffff" height="80px">
        <Box mr={2}>
          <Image width="35px" alt="logo" src={Logo} />
        </Box>

        <Select
          display={{ base: "inline", sm: "none" }}
          variant="outline"
          width="150px"
          defaultValue="Option 1"
          borderColor="gray.200"
          ml={4}
          onChange={handleChange}
        >
          <option value="top">Top</option>
          <option value="new">New</option>
          <option value="best">Best</option>
          <option value="show">Show</option>
        </Select>
        <Box display={{ base: "none", sm: "inline" }}>
          <Flex alignItems="center">
            {tabs.map((tab, index) => (
              <Box key={index}>
                <Flex alignItems="center">
                  <NavLink
                    key={tab}
                    setTab={setTab}
                    setActiveTab={setActiveTab}
                    tabName={tabs[index]}
                    style={{
                      color: activeTab === tab ? "#1A202C" : "#4A5568",
                      fontWeight: activeTab === tab ? "bold" : "normal",
                    }}
                    tab={tab}
                    setCount={setCount}
                  />
                  <Text color="gray.300"> |</Text>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Navbar;
