/* eslint-disable no-undef */
//libraries
import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Image } from "@chakra-ui/react";

//components
import ReaderArticleScroll from "../ReaderArticleScroll/ReaderArticleScroll";
import ReaderPreview from "../ReaderPreview/ReaderPreview";
import Emoji from "../Emoji/Emoji";

//images
import Logo from "../../images/logo.svg";

//constants

const Reader = ({ tab, count, setCount }) => {
  const [readerUrl, setReaderUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 600;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // const mobileOrDesktop = useBreakpointValue({
  //   base: (
  //     <Flex height="calc(100vh - 80px)" width="100%">
  //       <ReaderArticleScroll
  //         activeReaderUrl={readerUrl}
  //         setReaderUrl={setReaderUrl}
  //         tab={tab}
  //         count={count}
  //         setCount={setCount}
  //         setErrorMessage={setErrorMessage}
  //       />
  //     </Flex>
  //   ),
  //   md: (
  //     <Flex height="calc(100vh - 80px)" width="100%">
  //       <ReaderArticleScroll
  //         activeReaderUrl={readerUrl}
  //         setReaderUrl={setReaderUrl}
  //         tab={tab}
  //         count={count}
  //         setCount={setCount}
  //         setErrorMessage={setErrorMessage}
  //       />
  //       {!readerUrl ? (
  //         <Flex bgColor="#F9FBFD" width="100%" height="100%" justify="center">
  //           <Flex
  //             flexDirection="column"
  //             maxWidth="175px"
  //             lineHeight="25px"
  //             alignItems="center"
  //             justifyContent="center"
  //           >
  //             <Image src={Logo} width="80px" mb={6} />
  //             <Flex mb={2}>
  //               <Box mr={2}>
  //                 <Emoji symbol="👋" label="sheep" />
  //               </Box>
  //               <Text fontSize="md">Hey there!</Text>
  //             </Flex>
  //             <Text textAlign="center" color="gray.600">
  //               Click an item in the list to preview here
  //             </Text>
  //           </Flex>
  //         </Flex>
  //       ) : (
  //         <ReaderPreview
  //           readerUrl={readerUrl}
  //           errorMessage={errorMessage}
  //           setErrorMessage={setErrorMessage}
  //         />
  //       )}
  //     </Flex>
  //   ),
  // });

  // const mobile = window.innerWidth > 800;
  // // const mobile = false;

  // const mobile = (e) => {
  //   if (window.innerWidth > 800) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  if (width > breakpoint) {
    return (
      <Flex height="calc(100vh - 80px)" width="100%">
        <ReaderArticleScroll
          activeReaderUrl={readerUrl}
          setReaderUrl={setReaderUrl}
          tab={tab}
          count={count}
          setCount={setCount}
          setErrorMessage={setErrorMessage}
        />
        {!readerUrl ? (
          <Flex bgColor="#F9FBFD" width="100%" height="100%" justify="center">
            <Flex
              flexDirection="column"
              maxWidth="175px"
              lineHeight="25px"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={Logo} width="80px" mb={6} />
              <Flex mb={2}>
                <Box mr={2}>
                  <Emoji symbol="👋" label="sheep" />
                </Box>
                <Text fontSize="md">Hey there!</Text>
              </Flex>
              <Text textAlign="center" color="gray.600">
                Click an item in the list to preview here
              </Text>
            </Flex>
          </Flex>
        ) : (
          <ReaderPreview
            readerUrl={readerUrl}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex width="100%">
      <ReaderArticleScroll
        activeReaderUrl={readerUrl}
        setReaderUrl={setReaderUrl}
        tab={tab}
        count={count}
        setCount={setCount}
        setErrorMessage={setErrorMessage}
      />
    </Flex>
  );
};

export default Reader;
