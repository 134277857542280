/* eslint-disable no-undef */
import React, { useState } from "react";
import "./App.css";
import styled from "styled-components";
import Reader from "./Components/Reader/Reader";
import Navbar from "./Components/Navbar/Navbar";
// import Header from "./Components/Header/Header";
import { theme, ThemeProvider, CSSReset } from "@chakra-ui/react";

import { STORY_INCREMENT } from "./constants";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MGHDJDV",
};
TagManager.initialize(tagManagerArgs);

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brandOrange: "#FF6600",
  },
};

const App = () => {
  const [tab, setTab] = useState("top");
  const [count, setCount] = useState(STORY_INCREMENT);

  window.dataLayer.push({
    event: "pageview",
    page: {
      url: "https://loopnewsreader.com/",
      title: "home",
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <AppContainer>
        <Navbar tab={tab} setTab={setTab} setCount={setCount} />
        <Reader tab={tab} count={count} setCount={setCount} />
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
