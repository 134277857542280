/* eslint-disable no-undef */
import React from "react";
import { Box, Text } from "@chakra-ui/react";

const NavLink = ({ style, setTab, tabName, setActiveTab, tab, setCount }) => {
  return (
    <Box
      py={3}
      px={1}
      m={3}
      cursor="pointer"
      onClick={() => {
        setCount(0);
        setTab(`${tabName}`);
        setActiveTab(tab);
        console.log(tab);
      }}
      style={style}
    >
      <Text>{tabName}</Text>
    </Box>
  );
};

export default NavLink;
