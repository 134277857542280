// libraries
import React from "react";
import { Box, Text, HStack } from "@chakra-ui/react";

import { mapTime } from "../../../mappers/mapTime";

const StoryDetails = ({
  storyScore,
  storyDescendents,
  storyTime,
  storyAuthor,
  setReaderUrl,
  storyCommentsId,
}) => {
  return (
    <HStack spacing={2} flexDir="row" color="gray.500" fontSize="xs">
      {storyScore > 0 && (
        <>
          <Box>{storyScore} pts</Box>
          <Text>|</Text>
        </>
      )}
      {storyDescendents > 0 && (
        <>
          <Text
            onClick={(e) => {
              e.stopPropagation();
              setReaderUrl(
                `https://news.ycombinator.com/item?id=${storyCommentsId}`
              );
            }}
            _hover={{
              color: "gray.800",
            }}
            cursor="pointer"
          >
            {storyDescendents} comments
          </Text>
          <Text display={{ base: "none", lg: "inline" }}>|</Text>
        </>
      )}
      <Box display={{ base: "none", lg: "inline" }}>
        {storyTime > 0 && <Box>{mapTime(storyTime)} ago</Box>}
      </Box>
      <Box display={{ base: "none", lg: "inline" }}>by {storyAuthor}</Box>
    </HStack>
  );
};

export default StoryDetails;
