/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LogoError from "../../images/logo-error.svg";

import {
  Text,
  Flex,
  Image,
  Heading,
  Button,
  Link,
  Box,
} from "@chakra-ui/react";

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: solid 1px #e0e2e4;
  display: block;
  margin: 0px;
  padding: 0px;
`;

function fetchHtmlData(url) {
  return fetch(url)
    .then((res) => {
      if (!res.ok) throw new Error(`${res.status} ${res.statusText}`);
      return res;
    })
    .catch((error) => {
      return fetchProxy(url, 0);
    });
}

function fetchProxy(url, i = 0) {
  const proxy = [
    "https://cors.io/?",
    "https://jsonp.afeld.me/?url=",
    "https://cors-anywhere.herokuapp.com/",
  ];

  return fetch(proxy[i] + url)
    .then((res) => {
      if (!res.ok) throw new Error(`${res.status} ${res.statusText}`);
      return res;
    })
    .catch((error) => {
      if (i === proxy.length - 1) {
        throw error;
      }

      return fetchProxy(url, i + 1);
    });
}

const fixHtmlResponse = (htmlResponse, url) =>
  htmlResponse.replace(
    /<head([^>]*)>/i,
    `<head$1>
	<base href="${url}">
	<script>
        // X-Frame-Bypass navigation event handlers
        document.addEventListener('click', e => {
            if (frameElement && document.activeElement && document.activeElement.href) {
                e.preventDefault()
                frameElement.load(document.activeElement.href)
            }
        })
        document.addEventListener('submit', e => {
            if (frameElement && document.activeElement && document.activeElement.form && document.activeElement.form.action) {
                e.preventDefault()
                if (document.activeElement.form.method === 'post')
                    frameElement.load(document.activeElement.form.action, {method: 'post', body: new FormData(document.activeElement.form)})
                else
                    frameElement.load(document.activeElement.form.action + '?' + new URLSearchParams(new FormData(document.activeElement.form)))
            }
        })
    </script>
`
  );

const ReaderPreview = ({ readerUrl, errorMessage, setErrorMessage }) => {
  const [loading, setLoading] = useState(true);
  const [htmlData, setHtmlData] = useState("");
  // const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (!readerUrl) return;

    fetchHtmlData(readerUrl)
      .then((data) => data.text())
      .then((data) => {
        setHtmlData(fixHtmlResponse(data, readerUrl));
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  }, [readerUrl, setErrorMessage]);

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (errorMessage) {
    return (
      <Flex bgColor="#F9FBFD" width="100%" height="100%" justify="center">
        <Flex
          flexDirection="column"
          maxWidth="400px"
          lineHeight="25px"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={LogoError} width="80px" mb={6} />
          <Flex mb={2}>
            <Heading as="h3" size="md" color="gray.800">
              Oh no!
            </Heading>
          </Flex>
          <Text textAlign="center" color="gray.600">
            It seems reader view is not supported for this page.
          </Text>
          <Text textAlign="center" color="gray.600">
            {" "}
            Please view it in a new tab.
          </Text>
          <Link isExternal href={readerUrl} mt={4}>
            <Button variant="outline">Open link in new tab</Button>
          </Link>
        </Flex>
      </Flex>
    );
  }

  return (
    <Box height="100%" width="100%" backgroundColor="#f9fbfd">
      <IFrame title="link" srcDoc={htmlData} />
    </Box>
  );
};

export default ReaderPreview;
