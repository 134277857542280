/* eslint-disable no-undef */
import axios from "axios";

import { selectFields } from "../selectors/selectFields";

export const baseUrl = ` https://hacker-news.firebaseio.com/v0/`;
export const topStoriesUrl = `${baseUrl}topstories.json`;
export const bestStoriesUrl = `${baseUrl}beststories.json`;
export const newStoriesUrl = `${baseUrl}newstories.json`;
export const askStoriesUrl = `${baseUrl}askstories.json`;
export const showStoriesUrl = `${baseUrl}showstories.json`;
export const storyUrl = `${baseUrl}item/`;

export const getStory = async (storyId) => {
  const result = await axios
    .get(`${storyUrl + storyId}.json`)
    .then(({ data }) => data && selectFields(data));
  // console.log("2 -- getStory result: ", result);
  return result;
};

// use async so it doesn't block the rendering of the UI
export const getTopStoryIds = async () => {
  // go get list of story ids and once u get it, return it
  const result = await axios.get(topStoriesUrl).then(({ data }) => data);
  // console.log('1 -- getStoryIds result: ', result)
  return result;
};

export const getBestStoryIds = async () => {
  const result = await axios.get(bestStoriesUrl).then(({ data }) => data);
  return result;
};

export const getNewStoryIds = async () => {
  const result = await axios.get(newStoriesUrl).then(({ data }) => data);
  return result;
};

export const getAskStoryIds = async () => {
  const result = await axios.get(askStoriesUrl).then(({ data }) => data);
  return result;
};

export const getShowStoryIds = async () => {
  const result = await axios.get(showStoriesUrl).then(({ data }) => data);
  return result;
};
