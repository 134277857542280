/* eslint-disable no-undef */
// libraries
import React, { useState, useEffect, memo } from "react";
import { Box, Text, Flex, IconButton, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

//functions
import { getStory } from "../../services/HnApi";

//components
import StoryDetails from "./StoryDetails/StoryDetails";

const Story = memo(function Story({
  storyId,
  index,
  setReaderUrl,
  activeReaderUrl,
  setUrlList,
  urlList,
  setErrorMessage,
}) {
  const [story, setStory] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 600;

  useEffect(() => {
    getStory(storyId)
      // only setStory(data) if data exists and data.url exists
      .then((data) => data && data.url && setStory(data));
  }, [storyId]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const active =
    (activeReaderUrl && activeReaderUrl === story?.url) ||
    (activeReaderUrl &&
      activeReaderUrl === `https://news.ycombinator.com/item?id=${story?.id}`);

  const read = urlList.slice(0, urlList.length - 1).includes(story.url);

  const handleClick = () => {
    if (urlList.includes(story.url)) {
      return;
    } else {
      setUrlList((urlList) => urlList.concat(story.url));
      console.log(urlList);
    }
  };

  if (width > breakpoint) {
    return story && story.url ? (
      <Flex
        role="group"
        px={6}
        py={6}
        borderBottom="solid 1px #E6E6E6"
        alignItems="flex-start"
        data-testid="story"
        cursor="pointer"
        // justifyContent="space-between"
        style={{
          background: active ? "#F7FAFC" : "",
        }}
        _hover={{
          backgroundColor: "#fafafa",
        }}
        onClick={() => {
          if (active) {
            setReaderUrl(story.url);
            handleClick();
          } else {
            setErrorMessage();
            setReaderUrl(story.url);
            handleClick();
          }
        }}
      >
        <Flex
          style={{
            background: read ? "#CBD5E0" : "#FF6600",
          }}
          align="center"
          justify="center"
          borderRadius={2}
          minWidth="30px"
          minHeight="30px"
          mr={5}
        >
          <Text color="gray.100" fontWeight="bold">
            {index}
          </Text>
        </Flex>
        <Box
          flexGrow="1"
          // width={{ base: "70%", lg: "340px" }}
        >
          <Box>
            {active ? (
              <Text
                color="gray.900"
                fontSize="md"
                lineHeight="20px"
                mb={2}
                fontWeight="medium"
              >
                {story.title}
              </Text>
            ) : (
              <Text color="gray.800" fontSize="md" lineHeight="20px" mb={2}>
                {story.title}
              </Text>
            )}
          </Box>
          <StoryDetails
            storyScore={story.score}
            storyDescendents={story.descendants}
            storyTime={story.time}
            storyAuthor={story.by}
            setReaderUrl={setReaderUrl}
            storyCommentsId={story.id}
          />
        </Box>
        <Box ml={5}>
          {active ? (
            <Link
              href={story.url}
              isExternal
              opacity="1"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton icon={<ExternalLinkIcon />} size="sm" />
            </Link>
          ) : (
            <Link
              href={story.url}
              isExternal
              opacity="0"
              _groupHover={{
                opacity: "1",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton icon={<ExternalLinkIcon />} size="sm" />
            </Link>
          )}
        </Box>
      </Flex>
    ) : null;
  }

  return story && story.url ? (
    <Flex
      role="group"
      px={6}
      py={6}
      borderBottom="solid 1px #E6E6E6"
      alignItems="flex-start"
      data-testid="story"
      cursor="pointer"
      // justifyContent="space-between"
      style={{
        background: active ? "#F7FAFC" : "",
      }}
      _hover={{
        backgroundColor: "#fafafa",
      }}
      onClick={() => {
        if (active) {
          setReaderUrl(story.url);
          handleClick();
          window.open(story.url, "_blank");
        } else {
          setErrorMessage();
          setReaderUrl(story.url);
          handleClick();
          window.open(story.url);
        }
      }}
    >
      <Flex
        style={{
          background: read ? "#CBD5E0" : "#FF6600",
        }}
        align="center"
        justify="center"
        borderRadius={2}
        minWidth="30px"
        minHeight="30px"
        mr={5}
      >
        <Text color="gray.100" fontWeight="bold">
          {index}
        </Text>
      </Flex>
      <Box
        flexGrow="1"
        // width={{ base: "70%", lg: "340px" }}
      >
        <Box>
          {active ? (
            <Text
              color="gray.900"
              fontSize="md"
              lineHeight="20px"
              mb={2}
              fontWeight="medium"
            >
              {story.title}
            </Text>
          ) : (
            <Text color="gray.800" fontSize="md" lineHeight="20px" mb={2}>
              {story.title}
            </Text>
          )}
        </Box>
        <StoryDetails
          storyScore={story.score}
          storyDescendents={story.descendants}
          storyTime={story.time}
          storyAuthor={story.by}
          setReaderUrl={setReaderUrl}
          storyCommentsId={story.id}
        />
      </Box>
    </Flex>
  ) : null;
});

export default Story;
