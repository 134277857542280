/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from "react";
import { Flex } from "@chakra-ui/react";

// components
import Story from "../Story/Story";

// functions
import {
  getTopStoryIds,
  getBestStoryIds,
  getNewStoryIds,
  // getAskStoryIds,
  getShowStoryIds,
} from "../../services/HnApi";

//constants
import { STORY_INCREMENT, MAX_STORIES } from "../../constants";

const ReaderArticleScroll = ({
  activeReaderUrl,
  setReaderUrl,
  tab,
  count,
  setCount,
  setErrorMessage,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [storyIds, setStoryIds] = useState([]);
  const [urlList, setUrlList] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 600;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const isScrolling = useCallback(
    (e) => {
      if (
        (e.target.scrollHeight - e.target.scrollTop !== e.target.clientHeight &&
          width > breakpoint) ||
        isFetching
      ) {
        return false;
      } else if (
        (document.documentElement.scrollHeight !==
          window.pageYOffset + window.innerHeight &&
          width <= breakpoint) ||
        isFetching
      ) {
        return false;
      }
      setIsFetching(true);
    },
    [isFetching, width]
  );

  //
  useEffect(() => {
    // problem is here. When tab is clicked, Count is set to 0
    // but isFetching is set to false so the function quits without
    // setting the new count to 30
    if (!isFetching && count !== 0) return;
    if (count + STORY_INCREMENT >= MAX_STORIES) {
      setCount(MAX_STORIES);
    } else {
      setCount(count + STORY_INCREMENT);
    }

    setIsFetching(false);
  }, [isFetching, tab, count, setCount]);

  useEffect(() => {
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, [isScrolling]);

  useEffect(() => {
    if (tab === "top") {
      getTopStoryIds()
        .then((data) => {
          setStoryIds(data);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    } else if (tab === "best") {
      getBestStoryIds()
        .then((data) => {
          setStoryIds(data);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    } else if (tab === "new") {
      getNewStoryIds()
        .then((data) => {
          setStoryIds(data);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
      // } else if (tab === "ask") {
      //   getAskStoryIds()
      //     .then((data) => {
      //       setStoryIds(data);
      //       setIsFetching(false);
      //     })
      //     .catch((err) => console.log(err));
    } else if (tab === "show") {
      getShowStoryIds()
        .then((data) => {
          setStoryIds(data);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    }
  }, [tab]);

  if (width > breakpoint) {
    return (
      <Flex
        flex={{ base: "0 0 370px", lg: "0 0 500px" }}
        overflowY="scroll"
        flexDirection="column"
        // height={{base:"calc(100vh - 80px)", }}
        borderTop="solid 1px #e0e2e4"
        onScroll={isScrolling}
      >
        {storyIds.slice(0, count).map((storyId, index) => (
          <Story
            activeReaderUrl={activeReaderUrl}
            key={storyId}
            storyId={storyId}
            index={index + 1}
            setReaderUrl={setReaderUrl}
            setUrlList={setUrlList}
            urlList={urlList}
            setErrorMessage={setErrorMessage}
          />
        ))}
      </Flex>
    );
  }
  return (
    <Flex
      // flex={{ base: "0 0 370px", lg: "0 0 500px" }}
      width="100%"
      // overflowY="scroll"
      flexDirection="column"
      // height={{base:"calc(100vh - 80px)", }}
      borderTop="solid 1px #e0e2e4"
      onScroll={isScrolling}
    >
      {storyIds.slice(0, count).map((storyId, index) => (
        <Story
          activeReaderUrl={activeReaderUrl}
          key={storyId}
          storyId={storyId}
          index={index + 1}
          setReaderUrl={setReaderUrl}
          setUrlList={setUrlList}
          urlList={urlList}
          setErrorMessage={setErrorMessage}
        />
      ))}
    </Flex>
  );
};

export default ReaderArticleScroll;
